import styled from "styled-components";
import { breakpoints } from "styles";

const UnStyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
export const SubjectTopicGridContainer = styled.section`
  margin: 10px 0;
  padding: 32px 16px;
`;

export const SubjectTopicGridTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  font-weight: bold;
  line-height: 1.25;
  color: ${({ theme }) => theme.colors.brand[500]};
  margin: 32px 0;
  text-align: center;

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes["4xl"]};
    margin: 48px 0;
  }
`;

export const SubjectsContainer = styled(UnStyledList)`
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;

  @media ${breakpoints.tablet} {
    margin: 48px 0;
  }
`;

export const SubjectItem = styled.li`
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

export const SubjectButton = styled.button<{ $active?: boolean }>`
  --webkit-appearance: none;
  border: 2px solid
    ${({ theme, $active }) =>
      $active ? theme.colors.brand[500] : theme.colors.neutral[800]};
  color: ${({ $active, theme }) =>
    $active ? theme.colors.brand[500] : theme.colors.neutral[800]};
  background: ${({ $active, theme }) =>
    $active ? theme.colors.brand[50] : "none"};
  padding: 16px 24px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  border-radius: 6px;
  border-radius: 2px solid ${({ theme }) => theme.colors.periwinkle["500"]};
  cursor: pointer;
  font-weight: ${({ $active }) => ($active ? "bold" : "normal")};

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`;

export const SubjectChapterContainer = styled(UnStyledList)<{
  $active?: boolean;
}>`
  display: ${({ $active }) => ($active ? "flex" : "none")};
  list-style: none;
  overflow-x: scroll;
  padding: 20px 0 40px 0;
`;

export const SubjectChapterItem = styled.li`
  margin-right: 32px;

  @media ${breakpoints.tablet} {
    margin-right: 60px;
  }
`;

export const SubjectChapter = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.neutral[900]};
  margin: 0 0 16px 0;

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes.lg};
  }
`;

export const SubjectTopicContainer = styled(UnStyledList)``;

export const SubjectTopicItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 18px;
  }
`;

export const SubjectTopic = styled.a`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.neutral[800]};
  white-space: nowrap;
  text-transform: capitalize;
  text-decoration: none;

  &:visited {
    color: ${({ theme }) => theme.colors.neutral[800]};
  }

  @media ${breakpoints.tablet} {
    font-size: ${({ theme }) => theme.fontSizes.md};
  }
`;
