import React from "react";
import {
  SubjectButton,
  SubjectChapterContainer,
  SubjectChapterItem,
  SubjectChapter,
  SubjectItem,
  SubjectsContainer,
  SubjectTopicGridContainer,
  SubjectTopicGridTitle,
  SubjectTopicContainer,
  SubjectTopic,
  SubjectTopicItem
} from "./subject-topic-grid.styles";

import { subjectsData, subjectTopicData } from "./subject-topic-grid.data";
import { useSubjectTopicGrid } from "./use-subject-topic-grid";
import { Container } from "styles/common/container";
import { ISubjectTopicGridProps } from "./subject-topic-grid.types";
import { getGradeGroup, getSubjectInBahasa } from "utils/utils";

const SubjectTopicsGrid: React.FC<ISubjectTopicGridProps> = ({
  title = "Sukses nggak pernah instan. Latihan topik lain, yuk!"
}) => {
  const { activeSubject, handleActiveSubjectChange, isCurrentSubjectActive } =
    useSubjectTopicGrid();

  return (
    <SubjectTopicGridContainer>
      <SubjectTopicGridTitle>{title}</SubjectTopicGridTitle>
      <SubjectsContainer>
        {subjectsData.map((subject, idx) => (
          <SubjectItem key={`subject-${subject.value}-${idx}}`}>
            <SubjectButton
              data-testid={`subject-${subject.value}-button`}
              $active={Boolean(isCurrentSubjectActive(subject.value))}
              onClick={() => handleActiveSubjectChange(subject.value)}
            >
              {subject.label}
            </SubjectButton>
          </SubjectItem>
        ))}
      </SubjectsContainer>
      <Container>
        {Object.keys(subjectTopicData).map((subject) => (
          <SubjectChapterContainer
            $active={activeSubject === subject}
            key={subject}
          >
            {subjectTopicData[subject].grades.map((grade, idx) => (
              <SubjectChapterItem key={`grade-${grade.name}-${idx}}`}>
                <SubjectChapter>
                  {grade.name} {getGradeGroup(grade.name)}
                </SubjectChapter>
                <SubjectTopicContainer>
                  {grade.topics.map((topic, idx) => (
                    <SubjectTopicItem
                      key={`subject-topic-${topic.slug}-${idx}`}
                    >
                      <SubjectTopic
                        data-testid={`subject-topic-${topic.slug}-${idx}`}
                        href={`/tanya/chapter/${
                          grade.name
                        }/${getSubjectInBahasa(subject)}/${topic.slug}`}
                      >
                        {topic.name}
                      </SubjectTopic>
                    </SubjectTopicItem>
                  ))}
                </SubjectTopicContainer>
              </SubjectChapterItem>
            ))}
          </SubjectChapterContainer>
        ))}
      </Container>
    </SubjectTopicGridContainer>
  );
};

export { SubjectTopicsGrid };
export default SubjectTopicsGrid;
