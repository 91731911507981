import { useState } from "react";
import { subjectsData } from "./subject-topic-grid.data";
import { IUseSubjectTopicGrid } from "./subject-topic-grid.types";

const useSubjectTopicGrid: () => IUseSubjectTopicGrid = () => {
  const [activeSubject, setActiveSubject] = useState<string>(
    subjectsData[0].value
  );

  const handleActiveSubjectChange = (subject: string) =>
    setActiveSubject(subject);

  const isCurrentSubjectActive = (subject: string) => activeSubject === subject;

  return {
    activeSubject,
    handleActiveSubjectChange,
    isCurrentSubjectActive
  };
};

export { useSubjectTopicGrid };
