import { ISubjectData, ISubjectTopicData } from "./subject-topic-grid.types";

export const subjectsData: ISubjectData = [
  {
    label: "Matematika",
    value: "maths"
  },
  {
    label: "Fisika",
    value: "physics"
  },
  {
    label: "Kimia",
    value: "chemistry"
  }
];

export const subjectTopicData: ISubjectTopicData = {
  maths: {
    grades: [
      {
        name: "12",
        topics: [
          {
            name: "Peluang Wajib",
            slug: "Peluang-Wajib"
          },
          {
            name: "Kekongruenan dan Kesebangunan",
            slug: "Kekongruenan-dan-Kesebangunan"
          },
          {
            name: "Statistika Inferensia",
            slug: "Statistika-Inferensia"
          },
          {
            name: "Dimensi Tiga",
            slug: "Dimensi-Tiga"
          },
          {
            name: "Statistika Wajib",
            slug: "Statistika-Wajib"
          },
          {
            name: "Limit Fungsi Trigonometri",
            slug: "Limit-Fungsi-Trigonometri"
          },
          {
            name: "Turunan Fungsi Trigonometri",
            slug: "Turunan-Fungsi-Trigonometri"
          }
        ]
      },
      {
        name: "11",
        topics: [
          {
            name: "Barisan",
            slug: "Barisan"
          },
          {
            name: "Limit Fungsi",
            slug: "Limit-Fungsi"
          },
          {
            name: "Turunan",
            slug: "Turunan"
          },
          {
            name: "Integral",
            slug: "Integral"
          },
          {
            name: "Persamaan Lingkaran dan Irisan Dua Lingkaran",
            slug: "Persamaan-Lingkaran-dan-Irisan-Dua-Lingkaran"
          },
          {
            name: "Integral Tentu",
            slug: "Integral-Tentu"
          },
          {
            name: "Integral Parsial",
            slug: "Integral-Parsial"
          },
          {
            name: "Induksi Matematika",
            slug: "Induksi-Matematika"
          },
          {
            name: "Program Linear",
            slug: "Program-Linear"
          },
          {
            name: "Matriks",
            slug: "Matriks"
          },
          {
            name: "Transformasi",
            slug: "Transformasi"
          },
          {
            name: "Fungsi Trigonometri",
            slug: "Fungsi-Trigonometri"
          },
          {
            name: "Persamaan Trigonometri",
            slug: "Persamaan-Trigonometri"
          },
          {
            name: "Irisan Kerucut",
            slug: "Irisan-Kerucut"
          },
          {
            name: "Polinomial",
            slug: "Polinomial"
          }
        ]
      },
      {
        name: "10",
        topics: [
          {
            name: "Fungsi",
            slug: "Fungsi"
          },
          {
            name: "Trigonometri",
            slug: "Trigonometri"
          },
          {
            name: "Skalar dan vektor serta operasi aljabar vektor",
            slug: "Skalar-dan-vektor-serta-operasi-aljabar-vektor"
          },
          {
            name: "Logika Matematika",
            slug: "Logika-Matematika"
          },
          {
            name: "Persamaan Dan Pertidaksamaan Linear Satu Variabel Wajib",
            slug: "Persamaan-Dan-Pertidaksamaan-Linear-Satu-Variabel-Wajib"
          },
          {
            name: "Pertidaksamaan Rasional Dan Irasional Satu Variabel",
            slug: "Pertidaksamaan-Rasional-Dan-Irasional-Satu-Variabel"
          },
          {
            name: "Sistem Persamaan Linear Tiga Variabel",
            slug: "Sistem-Persamaan-Linear-Tiga-Variabel"
          },
          {
            name: "Sistem Pertidaksamaan Dua Variabel",
            slug: "Sistem-Pertidaksamaan-Dua-Variabel"
          },
          {
            name: "Sistem Persamaan Linier Dua Variabel",
            slug: "Sistem-Persamaan-Linier-Dua-Variabel"
          },
          {
            name: "Sistem Pertidaksamaan Linier Dua Variabel",
            slug: "Sistem-Pertidaksamaan-Linier-Dua-Variabel"
          },
          {
            name: "Grafik, Persamaan, Dan Pertidaksamaan Eksponen Dan Logaritma",
            slug: "Grafik-Persamaan-Dan-Pertidaksamaan-Eksponen-Dan-Logaritma"
          }
        ]
      },
      {
        name: "9",
        topics: [
          {
            name: "Transformasi Geometri",
            slug: "Transformasi-Geometri"
          },
          {
            name: "Kesebangunan dan Kongruensi",
            slug: "Kesebangunan-dan-Kongruensi"
          },
          {
            name: "Bangun Ruang Sisi Lengkung",
            slug: "Bangun-Ruang-Sisi-Lengkung"
          },
          {
            name: "Bilangan Berpangkat Dan Bentuk Akar",
            slug: "Bilangan-Berpangkat-Dan-Bentuk-Akar"
          },
          {
            name: "Persamaan Kuadrat",
            slug: "Persamaan-Kuadrat"
          },
          {
            name: "Fungsi Kuadrat",
            slug: "Fungsi-Kuadrat"
          }
        ]
      },
      {
        name: "8",
        topics: [
          {
            name: "Teorema Phytagoras",
            slug: "Teorema-Phytagoras"
          },
          {
            name: "Lingkaran",
            slug: "Lingkaran"
          },
          {
            name: "Garis Singgung Lingkaran",
            slug: "Garis-Singgung-Lingkaran"
          },
          {
            name: "Bangun Ruang Sisi Datar",
            slug: "Bangun-Ruang-Sisi-Datar"
          },
          {
            name: "Peluang",
            slug: "Peluang"
          },
          {
            name: "Pola Bilangan Dan Barisan Bilangan",
            slug: "Pola-Bilangan-Dan-Barisan-Bilangan"
          },
          {
            name: "Koordinat Cartesius",
            slug: "Koordinat-Cartesius"
          },
          {
            name: "Relasi Dan Fungsi",
            slug: "Relasi-Dan-Fungsi"
          },
          {
            name: "Persamaan Garis Lurus",
            slug: "Persamaan-Garis-Lurus"
          },
          {
            name: "Sistem Persamaan Linear Dua Variabel (Spldv)",
            slug: "Sistem-Persamaan-Linear-Dua-Variabel-Spldv"
          }
        ]
      },
      {
        name: "7",
        topics: [
          {
            name: "Perbandingan",
            slug: "Perbandingan"
          },
          {
            name: "Aritmetika Sosial (Aplikasi Aljabar)",
            slug: "Aritmetika-Sosial-Aplikasi-Aljabar"
          },
          {
            name: "Sudut dan Garis Sejajar",
            slug: "Sudut-dan-Garis-Sejajar"
          },
          {
            name: "Segi Empat",
            slug: "Segi-Empat"
          },
          {
            name: "Segitiga",
            slug: "Segitiga"
          },
          {
            name: "Statistika",
            slug: "Statistika"
          },
          {
            name: "Bilangan Bulat Dan Pecahan",
            slug: "Bilangan-Bulat-Dan-Pecahan"
          },
          {
            name: "Himpunan",
            slug: "Himpunan"
          },
          {
            name: "Operasi Dan Faktorisasi Bentuk Aljabar",
            slug: "Operasi-Dan-Faktorisasi-Bentuk-Aljabar"
          },
          {
            name: "Persamaan Dan Pertidaksamaan Linear Satu Variabel",
            slug: "Persamaan-Dan-Pertidaksamaan-Linear-Satu-Variabel"
          }
        ]
      },
      {
        name: "6",
        topics: [
          {
            name: "Bangun Ruang",
            slug: "Bangun-Ruang"
          },
          {
            name: "Statistika 6",
            slug: "Statistika-6"
          },
          {
            name: "Sistem Koordinat",
            slug: "Sistem-Koordinat"
          },
          {
            name: "Bilangan Bulat",
            slug: "Bilangan-Bulat"
          },
          {
            name: "Lingkaran",
            slug: "Lingkaran"
          }
        ]
      },
      {
        name: "5",
        topics: [
          {
            name: "Bangun Ruang",
            slug: "Bangun-Ruang"
          },
          {
            name: "Pengumpulan dan Penyajian Data",
            slug: "Pengumpulan-dan-Penyajian-Data"
          },
          {
            name: "Operasi Bilangan Pecahan",
            slug: "Operasi-Bilangan-Pecahan"
          },
          {
            name: "Kecepatan Dan Debit",
            slug: "Kecepatan-Dan-Debit"
          },
          {
            name: "Skala",
            slug: "Skala"
          },
          {
            name: "Perpangkatan Dan Akar",
            slug: "Perpangkatan-Dan-Akar"
          }
        ]
      },
      {
        name: "4",
        topics: [
          {
            name: "Aproksimasi / Pembulatan",
            slug: "Aproksimasi-Pembulatan"
          },
          {
            name: "Bangun Datar",
            slug: "Bangun-Datar"
          },
          {
            name: "Statistika",
            slug: "Statistika"
          },
          {
            name: "Pengukuran Sudut",
            slug: "Pengukuran-Sudut"
          },
          {
            name: "Bilangan Romawi",
            slug: "Bilangan-Romawi"
          },
          {
            name: "Pecahan",
            slug: "Pecahan"
          },
          {
            name: "KPK Dan FPB",
            slug: "Kpk-Dan-Fpb"
          }
        ]
      }
    ]
  },
  physics: {
    grades: [
      {
        name: "12",
        topics: [
          {
            name: "Teori Relativitas Khusus",
            slug: "Teori-Relativitas-Khusus"
          },
          {
            name: "Konsep dan Fenomena Kuantum",
            slug: "Konsep-dan-Fenomena-Kuantum"
          },
          {
            name: "Teknologi Digital",
            slug: "Teknologi-Digital"
          },
          {
            name: "Inti Atom",
            slug: "Inti-Atom"
          },
          {
            name: "Sumber-Sumber Energi",
            slug: "Sumber-Sumber-Energi"
          },
          {
            name: "Rangkaian Arus Searah",
            slug: "Rangkaian-Arus-Searah"
          },
          {
            name: "Listrik Statis (Elektrostatika)",
            slug: "Listrik-Statis-Elektrostatika"
          },
          {
            name: "Medan Magnet",
            slug: "Medan-Magnet"
          },
          {
            name: "Induksi Elektromagnetik",
            slug: "Induksi-Elektromagnetik"
          },
          {
            name: "Rangkaian Arus Bolak Balik",
            slug: "Rangkaian-Arus-Bolak-Balik"
          },
          {
            name: "Radiasi Elektromagnetik",
            slug: "Radiasi-Elektromagnetik"
          }
        ]
      },
      {
        name: "11",
        topics: [
          {
            name: "Hukum Termodinamika",
            slug: "Hukum-Termodinamika"
          },
          {
            name: "Ciri-Ciri Gelombang Mekanik",
            slug: "Ciri-Ciri-Gelombang-Mekanik"
          },
          {
            name: "Gelombang Berjalan dan Gelombang Stasioner",
            slug: "Gelombang-Berjalan-dan-Gelombang-Stasioner"
          },
          {
            name: "Gelombang Bunyi",
            slug: "Gelombang-Bunyi"
          },
          {
            name: "Gelombang Cahaya",
            slug: "Gelombang-Cahaya"
          },
          {
            name: "Alat-Alat Optik",
            slug: "Alat-Alat-Optik"
          },
          {
            name: "Gejala Pemanasan Global",
            slug: "Gejala-Pemanasan-Global"
          },
          {
            name: "Alternatif Solusi",
            slug: "Alternatif-Solusi"
          },
          {
            name: "Keseimbangan Dan Dinamika Rotasi",
            slug: "Keseimbangan-Dan-Dinamika-Rotasi"
          },
          {
            name: "Elastisitas Dan Hukum Hooke",
            slug: "Elastisitas-Dan-Hukum-Hooke"
          },
          {
            name: "Fluida Statik",
            slug: "Fluida-Statik"
          },
          {
            name: "Fluida Dinamik",
            slug: "Fluida-Dinamik"
          },
          {
            name: "Suhu, Kalor Dan Perpindahan Kalor",
            slug: "Suhu-Kalor-Dan-Perpindahan-Kalor"
          },
          {
            name: "Teori Kinetik Gas",
            slug: "Teori-Kinetik-Gas"
          }
        ]
      },
      {
        name: "10",
        topics: [
          {
            name: "Hukum Newton",
            slug: "Hukum-Newton"
          },
          {
            name: "Hukum Newton Tentang Gravitasi",
            slug: "Hukum-Newton-Tentang-Gravitasi"
          },
          {
            name: "Usaha (Kerja) Dan Energi",
            slug: "Usaha-Kerja-dan-Energi"
          },
          {
            name: "Momentum dan Impuls",
            slug: "Momentum-dan-Impuls"
          },
          {
            name: "Getaran Harmonis",
            slug: "Getaran-Harmonis"
          },
          {
            name: "Hakikat Fisika Dan Prosedur Ilmiah",
            slug: "Hakikat-Fisika-Dan-Prosedur-Ilmiah"
          },
          {
            name: "Pengukuran",
            slug: "Pengukuran"
          },
          {
            name: "Vektor",
            slug: "Vektor"
          },
          {
            name: "Gerak Lurus",
            slug: "Gerak-Lurus"
          },
          {
            name: "Gerak Parabola",
            slug: "Gerak-Parabola"
          },
          {
            name: "Gerak Melingkar",
            slug: "Gerak-Melingkar"
          }
        ]
      },
      {
        name: "9",
        topics: [
          {
            name: "Kelistrikan, Kemagnetan dan Pemanfaatannya dalam Produk Teknologi",
            slug: "Kelistrikan-Kemagnetan-dan-Pemanfaatannya-dalam-Produk-Teknologi"
          },
          {
            name: "Produk Teknologi",
            slug: "Produk-Teknologi"
          },
          {
            name: "Sifat Bahan",
            slug: "Sifat-Bahan"
          },
          {
            name: "Kelistrikan Dan Teknologi Listrik Di Lingkungan",
            slug: "Kelistrikan-Dan-Teknologi-Listrik-Di-Lingkungan"
          }
        ]
      },
      {
        name: "8",
        topics: [
          {
            name: "Tekanan",
            slug: "Tekanan"
          },
          {
            name: "Cahaya",
            slug: "Cahaya"
          },
          {
            name: "Getaran dan Gelombang",
            slug: "Getaran-dan-Gelombang"
          },
          {
            name: "Gerak Dan Gaya",
            slug: "Gerak-Dan-Gaya"
          },
          {
            name: "Pesawat Sederhana",
            slug: "Pesawat-Sederhana"
          }
        ]
      },
      {
        name: "7",
        topics: [
          {
            name: "Tata Surya",
            slug: "Tata-Surya"
          },
          {
            name: "Objek Ilmu Pengetahuan Alam Dan Pengamatannya",
            slug: "Objek-Ilmu-Pengetahuan-Alam-Dan-Pengamatannya"
          },
          {
            name: "Zat Dan Karakteristiknya",
            slug: "Zat-Dan-Karakteristiknya"
          },
          {
            name: "Suhu Dan Kalor",
            slug: "Suhu-Dan-Kalor"
          },
          {
            name: "Energi",
            slug: "Energi"
          },
          {
            name: "Fisika Geografi",
            slug: "Fisika-Geografi"
          }
        ]
      }
    ]
  },
  chemistry: {
    grades: [
      {
        name: "12",
        topics: [
          {
            name: "Struktur, Tata Nama, Sifat, Isomer, Identifikasi, dan Kegunaan Senyawa",
            slug: "Struktur-Tata-Nama-Sifat-Isomer-Identifikasi-dan-Kegunaan-Senyawa"
          },
          {
            name: "Benzena dan Turunannya",
            slug: "Benzena-dan-Turunannya"
          },
          {
            name: "Struktur, Tata Nama, Sifat, Penggunaan, dan Penggolongan Makromolekul",
            slug: "Struktur-Tata-Nama-Sifat-Penggunaan-dan-Penggolongan-Makromolekul"
          },
          {
            name: "Sifat Koligatif Larutan",
            slug: "Sifat-Koligatif-Larutan"
          },
          {
            name: "Reaksi Redoks Dan Sel Elektrokimia",
            slug: "Reaksi-Redoks-Dan-Sel-Elektrokimia"
          },
          {
            name: "Kimia Unsur",
            slug: "Kimia-Unsur"
          }
        ]
      },
      {
        name: "11",
        topics: [
          {
            name: "Asam dan Basa",
            slug: "Asam-dan-Basa"
          },
          {
            name: "Kesetimbangan Ion dan pH Larutan Garam",
            slug: "Kesetimbangan-Ion-dan-pH-Larutan-Garam"
          },
          {
            name: "Larutan Penyangga",
            slug: "Larutan-Penyangga"
          },
          {
            name: "Titrasi",
            slug: "Titrasi"
          },
          {
            name: "Kesetimbangan Larutan (Ksp)",
            slug: "Kesetimbangan-Larutan-Ksp"
          },
          {
            name: "Sistem Koloid",
            slug: "Sistem-Koloid"
          },
          {
            name: "Kimia Terapan",
            slug: "Kimia-Terapan"
          },
          {
            name: "Senyawa Hidrokarbon",
            slug: "Senyawa-Hidrokarbon"
          },
          {
            name: "Minyak Bumi",
            slug: "Minyak-Bumi"
          },
          {
            name: "Termokimia",
            slug: "Termokimia"
          },
          {
            name: "Laju Reaksi",
            slug: "Laju-Reaksi"
          },
          {
            name: "Kesetimbangan Kimia Dan Pergeseran Kesetimbangan",
            slug: "Kesetimbangan-Kimia-Dan-Pergeseran-Kesetimbangan"
          }
        ]
      },
      {
        name: "10",
        topics: [
          {
            name: "Larutan Elektrolit dan Larutan Non-Elektrolit",
            slug: "Larutan-Elektrolit-dan-Larutan-Non-Elektrolit"
          },
          {
            name: "Reaksi Reduksi dan Oksidasi serta Tata Nama Senyawa",
            slug: "Reaksi-Reduksi-dan-Oksidasi-serta-Tata-Nama-Senyawa"
          },
          {
            name: "Hukum-Hukum Dasar Kimia dan Stoikiometri",
            slug: "Hukum-Hukum-Dasar-Kimia-dan-Stoikiometri"
          },
          {
            name: "Metode Ilmiah, Hakikat Ilmu Kimia, Keselamatan dan Keamanan Kimia di Laboratorium, serta Peran Kimia dalam Kehidupan",
            slug: "Metode-Ilmiah-Hakikat-Ilmu-Kimia-Keselamatan-dan-Keamanan-Kimia-di-Laboratorium-serta-Peran-Kimia"
          },
          {
            name: "Struktur Atom Dan Tabel Periodik",
            slug: "Struktur-Atom-Dan-Tabel-Periodik"
          },
          {
            name: "Ikatan Kimia, Bentuk Molekul, Dan Interaksi Antarmolekul",
            slug: "Ikatan-Kimia-Bentuk-Molekul-Dan-Interaksi-Antarmolekul"
          }
        ]
      }
    ]
  }
};
